/* eslint-disable no-unused-vars */
import { api, request } from '../api';
import Vue from 'vue';

/**
 * 工作流程备注
 * class无法直接使用，实际导出的是实例，所以这个class只会new一次。
 * new的时候会走的那个拉取所有的menu和layout，
 * 然后执行 @method getExpressions 将所有的layout_json当中的expression提到数组当中。
 * 再然后执行 @method addKeyToStoreSet 将所有的key保存到storeKeys。
 * 此时初始化完成， @property {initFinished} = true
 * 当用户编辑json时，调用 @method resolveModifiedJson 传入一个json序列化之后的对象
 * @method resolveModifiedJson 会先不断轮循 @property {initFinished}，检查是否已经完成初始化
 * 如果轮训100次（5秒钟）没有结果，则reject。
 * 如果初始化完成，则检查 @property {repeatedKeys} 是否存在重复key，存在则reject
 * @method resolveModifiedJson 会调用 @method addKeyToExpression 为空缺的expression添加key，这里存在bug但是后续解决
 */

export class ExpressionScriptManage {
  constructor() {
    const intervalId = setInterval(() => {
      const path = window.location.pathname;
      const reg = /login/; // 一切以登录为路径的都过滤
      if (reg.test(path)) return;
      if (this.#initLoadning) return;
      if (!this.#initLoadning && !this.#initFinished) {
        console.log('---- ExpressionScriptManage 初始化未完成，重试中---');
        this.init();
      }
      if (!this.#initLoadning && this.#initFinished) {
        clearInterval(intervalId);
      }
    }, 1000);
  }
  #KEY_REG = /{{[^}]*}}/;
  #initFinished = false; // 是否初始化完成
  #initLoadning = false; // 是否进入初始化过程中
  /**
   * @type { {type: 'layout' | 'menu', id: number, name: string, path: string, key: string, expression: string}[] }
   */
  #repeatedKeys = []; // 是否存在重复key
  /**
   * @type { Set<string> }
   */
  #storeKeys = new Set();
  /**
   * 判断输入是否是string
   * @param {any} str - 需要判断的输入
   * @return {boolean} - 是否为string
   */
  isString(str) {
    return typeof str === 'string';
  }
  getUrlRelativePath() {
    const url = document.location.toString();
    const arrUrl = url.split('//');

    const start = arrUrl[1].indexOf('/');
    let relUrl = arrUrl[1].substring(start); // stop省略，截取从start开始到结尾的所有字符

    if (relUrl.indexOf('?') !== -1) {
      [relUrl] = relUrl.split('?');
    }
    return relUrl;
  }
  getMenuList() {
    const url = api.getMenuList;
    const payload = {
      appid: 2,
      platform: 1,
      page_size: 99999,
      page: 1,
    };
    return request({
      url,
      data: payload,
      showLoading: false,
    });
  }
  getLayoutList() {
    const url = api.getLayoutList;
    const payload = {
      layout_type: 'object',
      page: 1,
      page_size: 999999,
      appid: 2,
      platform: 1,
      object_code: '',
      type: '',
      keyword: '',
      name: '',
      usage: '',
    };
    return request({
      url,
      data: payload,
      showLoading: false,
    });
  }

  async init() {
    console.log('----init is running----');
    this.#initFinished = false;
    this.#initLoadning = true;
    this.#repeatedKeys = [];
    this.#storeKeys = new Set();
    try {
      const [getLayoutListRes, getMenuListRes] = await Promise.all([
        this.getLayoutList(),
        this.getMenuList(),
      ]);
      const layoutExpressions = this.getExpressions(
        getLayoutListRes.data.map((item) => {
          return { ...item, type: 'layout' };
        }),
      );
      const menuTreeExpression = this.getExpressions(
        getMenuListRes.data.map((item) => {
          return { ...item, type: 'menu' };
        }),
      );

      console.log('---layoutExpressions---', layoutExpressions);
      console.log('--- menuTreeExpressio---', menuTreeExpression);
      this.addKeyToStoreSet(layoutExpressions);
      this.addKeyToStoreSet(menuTreeExpression);
      this.#initFinished = true;
      this.#initLoadning = false;
      console.log('----init is finished----');
    } catch (e) {
      console.log('----init_error----', e);
      this.#initLoadning = false;
    }
  }
  /**
   * 判断输入是否是array
   * @param {any} arr - 需要判断的输入
   * @return {boolean} - 是否为array
   */
  isArray(arr) {
    return Array.isArray(arr);
  }
  /**
   * 判断输入是否是object
   * @param {any} obj - 需要判断的输入
   * @return {boolean} - 是否为object
   */
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
  /**
   * 返回对象的所有第一层属性
   * @param {object} obj
   * @return {string[]}
   */
  keysIn(obj) {
    return Object.keys(obj);
  }
  /**
   * 生成一个随机的8位16进制的id
   * @return {string}
   */
  genKey() {
    const t = 'xxxxxxxx';
    return t.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  /**
   * 生成一个全新的不重复id
   * @return {string}
   */
  genNewKey() {
    let tmpKey = this.genKey();
    while (this.#storeKeys.has(tmpKey)) {
      tmpKey = this.genKey();
    }
    this.#storeKeys.add(tmpKey);
    console.log('---new-key---', tmpKey);
    return tmpKey;
  }

  /**
   * 去掉表达式前后的两对花括号并返回
   * @param {string} expressionStr
   * @return {string}
   */
  getValidExpression(expressionStr = '') {
    return expressionStr.replace(/^{{/g, '').replace(/}}$/g, '');
  }

  /**
   * 从一个表达式当中，获取八位id
   * @param {string} expressionStr
   * @return {string}
   */
  getKey(expressionStr = '') {
    const validExpressionStr = this.getValidExpression(expressionStr);
    const matchKey = validExpressionStr.match(this.#KEY_REG);
    if (matchKey) {
      const key = this.getValidExpression(matchKey[0]).trim();
      if (key && !/[^\w]/g.test(key)) return key;
    }
    return '';
  }
  /**
   * 检测一个表达式是否存在id，不存在则生成。
   * 最终返回一个必定包含id的表达式
   * @todo 这里存在bug
   * @todo 对于存在key的expression，直接返回，但是存在的key可能是复制过来的，导致这里仍然可能重复
   * @todo 更好的解决办法是storeKey保存key所在的layout或者menu，
   * @todo 当保存一个json时，json当中的key需要和其他布局的key对比保证已有的key不会重复
   * @param {string} expressionStr
   * @return {string}
   */
  generateKey(expressionStr = '') {
    const oldKey = this.getKey(expressionStr);
    if (oldKey) {
      return expressionStr;
    }
    const validExpressionStr = this.getValidExpression(expressionStr);
    return `{{'{{${this.genNewKey()}}}'; ${validExpressionStr}}}`;
  }
  /**
   * 判断一个输入是否是表达式
   * @param {any} expressionStr
   * @return {boolean}
   */
  isExpression(expressionStr = '') {
    return (
      this.isString(expressionStr) && expressionStr.startsWith('{{') && expressionStr.endsWith('}}')
    );
  }

  /**
   * 遍历一个json，处理其中每一个是expression的字段，添加id，并返回。
   * @param {any} data
   * @returns {any}
   */
  addKeyToExpression(data) {
    let obj;
    if (this.isArray(data)) {
      obj = [];
      data.forEach((item) => {
        obj.push(this.addKeyToExpression(item));
      });
    } else if (this.isObject(data)) {
      obj = {};
      this.keysIn(data).forEach((key) => {
        obj[key] = this.addKeyToExpression(data[key]);
      });
    } else {
      // 不再具有下一层次
      if (this.isExpression(data)) {
        return this.generateKey(data);
      }
      return data;
    }
    return obj;
  }
  /**
   * 遍历一个json，收集所有expression数组。
   * @param {any} data
   * @returns {string[]}
   */
  findExpression(data) {
    let list = [];
    if (this.isArray(data)) {
      data.forEach((item) => {
        list = list.concat(this.findExpression(item));
      });
    } else if (this.isObject(data)) {
      this.keysIn(data).forEach((key) => {
        list = list.concat(this.findExpression(data[key]));
      });
    } else {
      // 不再具有下一层次
      this.isExpression(data) && list.push(data);
    }
    return list;
  }

  /**
   * 将每一个layout或者menu的layput_json的表达式提取出来。
   * @param {{ id: number, name: string, path: string, layout_json: string }[]} jsons
   * @returns { {id: number, name: string, path: string, expressions: string}[] }
   */
  getExpressions(jsons) {
    /**
     * @type  { {id: number, name: string, path: string, layout_json: string, expressions: string[]}[] }
     */
    const result = [];
    jsons.forEach((item) => {
      try {
        const expressions = this.findExpression(JSON.parse(item.layout_json || '{}'));
        if (expressions.length) {
          result.push({
            ...item,
            expressions,
          });
        }
      } catch (e) {
        console.error(e);
      }
    });
    return result;
  }
  /**
   * 将getExpressions整理出的id保存
   * 同时检测是否存在重复的key。如果存在重复key，保存到repeatedKeys
   * @param { {type: 'layout' | 'menu', id: number, name: string, path: string, key: string, expressions: string[]}[] } expressionList
   */
  addKeyToStoreSet(expressionList) {
    expressionList.forEach((item) => {
      const { expressions } = item;
      expressions.forEach((expression) => {
        const key = this.getKey(expression);
        if (this.#storeKeys.has(key)) {
          this.#repeatedKeys.push({
            id: item.id,
            type: item.type,
            name: item.name,
            path: item.path || '',
            key,
            expression,
          });
        } else {
          this.#storeKeys.add(key);
        }
      });
    });
  }

  getJsons(list) {
    const menuJson = [];
    list.forEach((menuItem) => {
      if (menuItem.layout_json) {
        try {
          menuJson.push({
            id: menuItem.id,
            path: menuItem.path,
            name: menuItem.name,
            layout_json: JSON.parse(menuItem.layout_json),
          });
        } catch (e) {
          console.error(
            '\x1B[41m%s\x1B[0m',
            'layout_json error:',
            `${menuItem.id}-${menuItem.name}`,
            menuItem.layout_json,
          );
          console.error('\x1B[41m%s\x1B[0m', 'layout_json error:', e);
          throw e;
        }
      }
      if (menuItem.children && menuItem.children.length) {
        menuItem.children.forEach((menuChild) => {
          if (menuChild.layout_json) {
            try {
              menuJson.push({
                id: menuChild.id,
                path: menuChild.path,
                name: menuChild.name,
                layout_json: JSON.parse(menuChild.layout_json),
              });
            } catch (e) {
              console.error(
                '\x1B[41m%s\x1B[0m',
                'layout_json error:',
                `${menuChild.id}-${menuChild.name}`,
                menuChild.layout_json,
              );
              console.error('\x1B[41m%s\x1B[0m', 'layout_json error:', e);
              throw e;
            }
          }
        });
      }
    });
    return menuJson;
  }

  resolveModifiedJson(json) {
    return new Promise((resolve, reject) => {
      try {
        const jsonParsed = JSON.parse(json);
        let attempts = 0;
        const intervalId = setInterval(() => {
          attempts++;
          if (this.#initFinished) {
            clearInterval(intervalId);
            const resolvedJson = this.addKeyToExpression(jsonParsed);
            resolve(JSON.stringify(resolvedJson));
            return;
          }
          if (attempts >= 100) {
            reject(new Error('Timeout: finished is still false after 100 attempts.'));
          }
        }, 50);
      } catch (e) {
        // 不合法json不做处理，防止layout为空传入引发报错
        // 调用方自行保证json格式没问题
        resolve(json);
      }
    });
  }

  getStoreKeys() {
    return this.#storeKeys;
  }
  async getRepeatedKeys() {
    // 重复 key 来自取全量数据是计算出来的
    // 最好的实现应该是保存所有的 layout
    // 当提交更新时，更新 class 内保存的对应 layout
    // 然后重新计算重复 key
    // 但是改造成本太大，暂时改成这种形式
    // 保存之后重新执行一遍init
    // 然后返回重复 key
    await this.init();
    return this.#repeatedKeys;
  }
}
