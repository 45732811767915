<template>
  <el-dialog
    :title="title || '布局json差异对比'"
    :visible.sync="visible"
    :width="width"
    top="0"
    :close-on-click-modal="false"
    :custom-class="{ full: width === '100%' }"
  >
    <template slot="title">
      <div class="custom-title-container">
        <div class="title">{{ title || '布局json差异对比' }}</div>
        <el-button @click="fullScreen">全屏</el-button>
        <el-button @click="smallScreen">小屏</el-button>
      </div>
    </template>
    <code-diff
      v-if="oldString !== newString"
      :old-string="oldString"
      :new-string="newString"
      :context="10"
      output-format="side-by-side"
    />
    <div v-else>无差异</div>
  </el-dialog>
</template>

<script>
import CodeDiff from 'vue-code-diff';
export default {
  components: {
    CodeDiff,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    oldString: {
      type: String,
      default: '',
    },
    newString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      codeDiffContainer: null,
      width: '60%',
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      },
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.$nextTick(() => {
          this.codeDiffContainer = document.querySelectorAll('.d2h-file-side-diff');
          for (let i = 0; i < this.codeDiffContainer.length; i++) {
            const element = this.codeDiffContainer[i];
            element.addEventListener('scroll', this.syncScrollTop);
          }
        });
      } else {
        for (let i = 0; i < this.codeDiffContainer.length; i++) {
          const element = this.codeDiffContainer[i];
          element.removeEventListener('scroll', this.syncScrollTop);
        }
        this.smallScreen();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    syncScrollTop(e) {
      for (let i = 0; i < this.codeDiffContainer.length; i++) {
        const element = this.codeDiffContainer[i];
        element.scrollTop = e.target.scrollTop;
      }
    },
    fullScreen() {
      this.width = '100%';
      const heightCon = document.querySelector('.d2h-files-diff');
      heightCon.style.minHeight = 'calc(100vh - 78px)';
    },
    smallScreen() {
      this.width = '60%';
      const heightCon = document.querySelector('.d2h-files-diff');
      heightCon.style.minHeight = '80vh';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.full {
      box-shadow: 0 -10px 20px #fff;
    }
    .custom-title-container {
      display: flex;
      align-items: center;
      .title {
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        margin-right: 20px;
      }
      .el-button {
        font-weight: 400;
        padding: 5px 12px;
        min-height: 32px;
        border-radius: 2px;
      }
    }
  }
  .el-dialog__body {
    padding: 0 8px;
    overflow: hidden;
  }
  .d2h-diff-tbody {
    line-height: 14px;
    tr:nth-child(1) {
      display: none;
    }
  }
  .d2h-files-diff {
    min-height: 80vh;
  }
  .d2h-file-side-diff {
    height: 100%;
    overflow-y: scroll;
    position: absolute;

    & + .d2h-file-side-diff {
      right: 0;
    }
  }
}
</style>
