import routes from '@/router/routes';
import { api, request } from '../../api/index.js';
import { PASS_MENU, DEFAULT_MENU } from '@/utils/menuConfig';
// 菜单name数据
const getNameList = (menuList = []) => {
  let menuNames = [];
  menuList.forEach((item) => {
    if (item.path) {
      menuNames.push(item.path);
    }
    if (item.name) {
      menuNames.push(item.name);
    }
    if (item.children && item.children.length > 0) {
      const childNames = getNameList(item.children);
      menuNames = [...menuNames, ...childNames];
    }
  });
  return menuNames;
};
const regRouteNameList = getNameList(routes); // 已注册的name
// 获取租户用户的菜单列表
const getUserMenuList = (list) => {
  let ststemIndex = 0;
  let systemApp = null;
  list.forEach((item, index) => {
    if (item.code === 'system') {
      ststemIndex = index;
      item.children.forEach((child) => {
        if (['system-app', 'system_app'].includes(child.path)) {
          systemApp = child;
        }
      });
    }
  });
  if (ststemIndex === 0) {
    return [...DEFAULT_MENU, ...list];
  }
  const newList = JSON.parse(JSON.stringify(list));
  newList[ststemIndex] = {
    ...DEFAULT_MENU[1],
  };
  if (systemApp) {
    newList[ststemIndex].children.unshift(systemApp);
  }
  newList.unshift(DEFAULT_MENU[0]); // 主页
  return newList;
};
// 获取首个显示的菜单(display === 1 && 是注册过的路由)
const getFristDisplayMenu = (list) => {
  let deName;
  const firlt = list.find((item) => {
    const condition1 = item.display === 1 && regRouteNameList.includes(item.path || item.code);
    let condition2 = true;
    if (item.children.length) {
      condition2 = getFristDisplayMenu(item.children);
      if (condition2) {
        deName = condition2;
      }
    }
    return condition1 && condition2;
  });
  if (firlt) {
    return deName || firlt.path;
  }
  return;
};

export default {
  namespaced: true,
  getters: {
    isLogin(state) {
      return state.userInfo && (state.userInfo.uin || state.userInfo.staffname);
    },
    getUserInfo(state) {
      return state.userInfo || {};
    },
    getMenuList(state) {
      const menuList = state.menuList?.filter(({ code }) => code !== 'login');
      if (state.userInfo?.uin === state.tenantInfo?.id && !menuList?.length) {
        return getUserMenuList(state.menuList);
      }
      return state.menuList || [];
    },
    // 用户默认菜单
    getDefaultMenuName(state, getters) {
      if (getters.getJurisdiction.includes('home')) {
        return 'home';
      }
      return getFristDisplayMenu(getters.getMenuList);
    },
    // 用户能进入的菜单(name列表)
    getJurisdiction(state) {
      let configMenus = getNameList(state.menuList);
      if (state.userInfo?.uin === state.tenantInfo?.id) {
        configMenus = [...configMenus, ...getNameList(DEFAULT_MENU)];
      }
      return [...configMenus, ...PASS_MENU] || PASS_MENU;
    },
    getElementList(state) {
      return state.elementList.map(({ code }) => code) || [];
    },
    getTenantInfo(state) {
      return state.tenantInfo || {};
    },
    getBreadcrumb(state) {
      return state.breadcrumb;
    },
    getAppList(state) {
      return state.appList;
    },
    getCurrentEnv(state) {
      return state.env;
    },
  },
  state: {
    userInfo: {},
    menuList: [],
    elementList: [],
    tenantInfo: {},
    breadcrumb: [],
    appList: [],
    env: '',
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setMenuList(state, menuList) {
      state.menuList = menuList || [];
    },
    setUserElementList(state, elementList) {
      state.elementList = elementList || [];
    },
    setTenantInfo(state, data) {
      state.tenantInfo = data;
    },
    setBreadcrumb(state, data) {
      state.breadcrumb = data;
    },
    setAppList(state, data) {
      state.appList = data;
    },
    setCurrentEnv(state, data) {
      state.env = data;
    },
  },
  actions: {
    async GetUserMenuTree(context) {
      try {
        const { data } = await request({ url: api.getUserMenuTree, data: { version: 'beta' } });
        let menus = data;
        if (data.length === 0) {
          menus = [
            {
              appid: 1,
              children: [],
              code: 'login',
              description: '',
              display: 2,
              icon: '-',
              id: 1162475,
              layout_json: '',
              name: '登录',
              order_weight: 10,
              parent_code: 'root',
              path: 'login',
            },
            ...data,
          ];
        }
        context.commit('setMenuList', menus);
      } catch (err) {
        console.log(err);
      }
    },
    async GetUserUserInfo(context) {
      try {
        let mockdata = {
          staffname: 'rouisli',
          uin: '',
        };
        const data = await request({ url: api.getUserinfo, data: {} });
        mockdata = {
          ...data,
          staffname: data.name,
        };
        sessionStorage.setItem('userinfo-ams', JSON.stringify(mockdata));
        context.commit('setUserInfo', mockdata);
      } catch (err) {
        console.log(err);
      }
    },
    async GetTenantInfo(context) {
      try {
        const data = await request({ url: api.getTenantInfo, data: {} });
        context.commit('setTenantInfo', data);
      } catch (err) {
        console.log(err);
      }
    },
    async GetUserElementList(context) {
      try {
        const { data } = await request({ url: api.getUserElementList, data: {} });
        context.commit('setUserElementList', data);
      } catch (err) {
        console.log(err);
      }
    },
    async GetAppOption(context) {
      const cb = (data) => {
        context.commit('setAppList', data.data);
      };
      request({
        url: api.getAppList,
        data: {
          page: 1,
          page_size: 9999,
        },
        cb,
      });
    },
    async GetCurrentEnv(context) {
      try {
        const { env } = await request({ url: api.getCurrentEnv, data: {} });
        context.commit('setCurrentEnv', env);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
